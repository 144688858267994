<template>
    <div class="col d-sm-flex justify-content-center">
        <div class="card text-center">
            <h1>Hai cancellato l'acquisto.</h1>
            <p>Nessuna somma di denaro è stata addebitata sul tuo conto PayPal.</p>
            <br>
            <router-link to="/" class="btn btn-primary btn-block">Torna alla home</router-link>
        </div>
    </div>
</template>

<script>

export default {
    name: "purchase-canceled",

};
</script>
